import {createColumnHelper} from "@tanstack/react-table";
import {ColumnDefTemplate} from "@tanstack/table-core/src/types";
import {CellContext} from "@tanstack/table-core/src/core/cell";
import {StringOrTemplateHeader} from "@tanstack/table-core/build/lib/types";

export class ReactTableHelper {
  public static createHelper<T>() {
    const helper = createColumnHelper<T>();
    type Params = {
      header?: StringOrTemplateHeader<T, any>,
      cell?:ColumnDefTemplate<CellContext<T, any>>,
      sortingFn?: any,
    };
    return {
      accessor: helper.accessor,
      columnNumber: (name: string, params?:Params)=>{
        return helper.accessor(name as any, Object.assign({
          header: () => name,
          cell: function (info:any) { return info.getValue()},
          meta: {classNames:['number']},
        }, params))
      },

      columnMoney: (name: string, params?:Params)=>{
        return helper.accessor(name as any, Object.assign({
          header: () => params?.header||name,
          cell: function (info:any) { return '$'+info.getValue()},
          meta: {classNames:['money']},
        }, params));
      },


    }
  }
}
