import React, { useEffect, useState } from "react";
import {
  Analysis,
  AnalysisInputParams, ISignalAlert,
} from "predictagram-lib";
import { Field, Form, Formik } from "formik";
import { FieldWithError } from "../../../common";
import { Spinner } from "components/common/Spinner";
import { SubmitModeEnum } from "./AdminAnalysisStrategyProfit";
import { StrategyProfitModel as model } from "models/strategy-profit.model";
import { AnalysisForm } from "./AnalysisForm";
import { Debug } from "components/common/Debug";

export interface StrategyFilterOptionsWithAlert extends StrategyFilterOptions {
  alert: Partial<ISignalAlert>,
}

export interface StrategyFilterOptions extends Analysis.InputParams {
  // symbolName: string,

  last30dScore?: number, // @TODO: better to refactor, it's just for UI
// {
//     name: string,
//     emails: string[],
//     options: {
//       sendCloseSignals: boolean
//     },
//     // last30dScore: number,
//   }
}

export const Filter: React.FunctionComponent<{
  initialValues: Partial<StrategyFilterOptions>,
  onClick: (search: any, submitMode: SubmitModeEnum) => Promise<void>,
  isEditing: boolean,
}> = ({ initialValues, onClick, isEditing }) => {

  const [submitMode, setSubmitMode] = useState<SubmitModeEnum>(SubmitModeEnum.NEW);

  useEffect(() => {
    setSubmitMode(initialValues !== undefined && initialValues !== null ? SubmitModeEnum.UPDATE : SubmitModeEnum.NEW)
  }, [initialValues])

  const initVals = Object.assign(model.defVals, initialValues) as StrategyFilterOptions;


  const onSubmit = async (opts: StrategyFilterOptions, actions: any) => {
    await onClick(opts, submitMode);
    actions.setSubmitting(false);
  }

  return (
    <div>
      <div className="page-title mb-3">Signal Alerts</div>

      <Formik initialValues={initVals} enableReinitialize onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, isSubmitting, validateForm }) => {
          return <Form>
            {/* <Debug><pre>Signal Alerts {JSON.stringify(values, null, 2)}</pre></Debug> */}
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-end gap-2">

              <div className="d-flex gap-4 align-items-center">
                <div className="d-flex flex-column gap-1">
                  <FieldWithError size={70} errors={errors} touched={touched} fieldName="alert.name" label="Alert Name" />
                  <div className="d-flex justify-content-between flex-column flex-md-row gap-3" >
                    <FieldWithError size={10} errors={errors} touched={touched} fieldName="alert.parentId" label="Alert Parent Id" />
                    <FieldWithError size={40} errors={errors} touched={touched} fieldName="alert.notes" label="Alert Notes" />
                    <FieldWithError size={20} errors={errors} touched={touched} fieldName="alert.emails" label="Alert Emails" />
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex flex-column gap-1">
                  <div className="align-self-start ms-2">
                    <label>
                      <Field type="checkbox" name="alertSendCloseSignals" />
                      <span className="mx-1">Send Close Signals</span>
                    </label>
                  </div>

                  <div className="d-flex justify-content-center align-items-center gap-2 ms-2">
                    {isSubmitting && <Spinner minHeight={50} />}
                    {isEditing ?
                      <div className="d-flex gap-1">
                        <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.UPDATE)} className="btn btn-primary p-2" disabled={isSubmitting}>Update Alert</button>
                        <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.SAVE_AS)} className="btn btn-primary p-2" disabled={isSubmitting}>Save As New</button>
                        <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.SAVE_AS_NEW_CHILD)} className="btn btn-primary p-2" disabled={isSubmitting}>Save As Child</button>
                      </div>
                      :
                      <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.NEW)} className="btn btn-primary p-2" disabled={isSubmitting}>Create Alert</button>
                    }
                    <div className="d-flex justify-content-end gap-2">
                      <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.ANALYZE)} className="btn btn-primary p-2" disabled={isSubmitting}>Analyze</button>
                      <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.DAILY_REPORT)} className="btn btn-primary p-2" disabled={isSubmitting}>Daily Report</button>
                      <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.CUME_CHART)} className="btn btn-primary p-2" disabled={isSubmitting}>Cumulative Chart</button>
                    </div>

                    {isEditing && <button type="submit" onClick={() => setSubmitMode(SubmitModeEnum.CREATE_TRADE_SETUP)} className="btn btn-primary p-2" disabled={isSubmitting}>Create Trade Setup</button>}

                  </div>
                </div>
              </div>

            </div>

            <hr />

            <AnalysisForm initialValues={initialValues as StrategyFilterOptions}
              values={values as StrategyFilterOptions}
              errors={errors}
              setFieldValue={setFieldValue}
              touched={touched} />

          </Form>
        }}

      </Formik>
    </div>
  )
};
