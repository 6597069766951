import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  AutoTradeHelper,
  AutoTradeManagementTypeEnumHelper,
  AutoTradePositionTypeEnumHelper,
  AutoTradeStatusEnum,
  AutoTradeStatusEnumHelper,
  AutoTradeStatusReasonEnumHelper,
  IAutoTrade,
  TriggerExitTypeEnumHelper,
} from 'predictagram-lib';
import { adminApiServiceCommon } from "../../../services/AdminApiService";
import { MessengerContext } from "../../common/messenger";
import { PresetDateRange } from './filters/PresetDateRangeFilter';
import {
  FunnelFill,
  Funnel,
  Grid3x2,
  CaretDownFill,
  CaretRightFill,
  ArrowsCollapse,
  ArrowsExpand,
  GraphUpArrow,
  ArrowClockwise,
  PlusCircle,
  XCircle,
} from 'react-bootstrap-icons'
import { IQuantity, TradeModel } from 'models/trade.model';
import { ExitOverlay } from './ExitOverlay';
import { ManualTradeCloseModal } from './ManualTradeCloseModal';
import { formatUSCurrency, colorize } from './Trades';
import { LinkAdminTradeSetup } from './LinkAdminTradeSetup';
import { useAdminSignalAlerts } from '_hooks/useAdminSignalAlerts';
import { SignalPerformance } from './TradeSetupListPage';
import { useTrades } from './hooks/useTrades';
import { NewYorkDate } from 'components/common/NewYorkDate';
import { TradeLivePrice } from './TradeLivePrice';
import { ManualTradeAddModal } from './ManualTradeAddModal';
import { IbkrOrder } from './IbkrOrder';
import { UrlHelper } from '_utils/UrlHelper';
import { Link } from 'react-router-dom';
import { IConsensusState } from './ConsensusOrdersGroupedPage';
import { IbkrAccountStatus } from './IbkrAccountStatus';


interface IGroupedTrade {
  setupId: number,
  setupName: string,
  alertScore: null|number,
  signalAlertId: number|null,
  stockSymbolId: number,
  trades: IAutoTrade[]
}

type TradeGroup = { [key: string]: IAutoTrade[] };

export const TradesListGroupBySetupPage: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);

  const [tradeSetupId, setTradeSetupId] = useState<number | undefined>(undefined);

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const tradeSetupCellRef = useRef<Array<HTMLDivElement | null>>([]);
  const exitTradeOverlayRef = useRef<HTMLDivElement | null>(null);
  const [expandedTradeGroups, setExpandedTradeGroups] = useState<number[]>([]);

  const [exitOverlayPosition, setExitOverlayPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });


  const [activeTrade, setActiveTrade] = useState<IAutoTrade | undefined>(undefined);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);

  const adminSignalAlerts = useAdminSignalAlerts();

  const [groupedTrade, setGroupedTrade] = useState<IGroupedTrade[]>([]);

  const [activeRowTradeId, setActiveRowTradeId] = useState<number | undefined>(undefined);
  const [showRefreshTradeIds, setShowRefreshTradeIds] = useState<number[]>([]);


  const tradesApi = useTrades(null);

  useEffect(() => {
    const grouped = tradesApi.filteredData
      .sort((a, b) => b.setupId - a.setupId)
      .reduce((acc: TradeGroup, trade: IAutoTrade) => {
        const key = JSON.stringify({ setupId: trade.setupId, setupName: trade.setupName, stockSymbolId: trade.stockSymbolId, signalAlertId: trade.setupSignalAlertId });
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(trade);
        return acc
      }, {})


    const data: IGroupedTrade[] = Object.entries(grouped).map(([key, items]) => {
      const { setupId, setupName, stockSymbolId,signalAlertId } = JSON.parse(key);
      // render if all trades have same
      const scores = Array.from(new Set(items.map(v=>v.signalAlertScore)));
      return {
        alertScore: scores.length===1 ? scores[0] : null,
        setupId,
        setupName,
        trades: items,
        stockSymbolId,
        signalAlertId
      }
    })

    setGroupedTrade(data);

  }, [tradesApi.filteredData]);


  const closeTrade = async () => {
    if (!activeTrade) {
      msgrContext.setMessage({ body: 'Missing Trade. Nothing done.' });
      return;
    }
    const id = activeTrade.id;
    setIsDeleting(true);
    try {
      const res = await adminApiServiceCommon.closeTrade(id);
      if (res) {
        msgrContext.setMessage({ body: `Closed #${id}` });
        tradesApi.filters.presetDateRange.set(PresetDateRange.TODAY);
        tradesApi.filters.status.set(undefined);
        tradesApi.reload();
      } else {
        console.error(JSON.stringify(res, null, 2));
        throw Error(`unexpected error trying to close`);
      }
    } catch (error: any) {
      msgrContext.setMessage({ body: `${(error as Error).message}` });
    } finally {
      setIsDeleting(false);
      setShowCloseModal(false);
    }
  }

  const addTrade = async () => {
    if (!activeTrade) {
      msgrContext.setMessage({ body: 'Missing Trade. Nothing done.' });
      return;
    }
    const id = activeTrade.id;
    setIsAdding(true);
    try {
      const res = await adminApiServiceCommon.addTrade(id);
      if (res) {
        msgrContext.setMessage({ body: `Added #${res.id}` });
        tradesApi.filters.presetDateRange.set(PresetDateRange.TODAY);
        tradesApi.filters.status.set(undefined);
        tradesApi.reload();
      } else {
        console.error(JSON.stringify(res, null, 2));
        throw Error(`unexpected error trying to add`);
      }
    } catch (error: any) {
      msgrContext.setMessage({ body: `${(error as Error).message}` });
    } finally {
      setIsAdding(false);
      setShowAddModal(false);
    }
  }

  const getSecurityNameFilter = (trade: IAutoTrade, index: number) => {
    const ibkrOpenOrder = trade.ibkr.openOrders.length > 0 ? trade.ibkr.openOrders[0] : undefined;
    if (!ibkrOpenOrder) {
      return <></>;
    }
    const securityName = TradeModel.getSecurityOptionName(ibkrOpenOrder);
    return <div className="d-flex gap-1 flex-row flex-nowrap">
      {securityName}
      <div role="button" onClick={()=>window.open(UrlHelper.getAdminConsensusOrders(trade.id))} >
        <GraphUpArrow style={{ color: 'blue' }} />
      </div>

      <div role="button" onClick={() => tradesApi.toggleSecurityFilter(securityName)}>{tradesApi.filters.security.value ? <FunnelFill color='gray' /> : <Funnel color='blue' />}</div>
    </div>
  }


  const onMouseOverTradeCell = (tradeId: number) => {
    const overlay = exitTradeOverlayRef.current;
    const cell = tradeSetupCellRef.current[tradeId];
    if (!overlay || !cell) { return; }

    const cellRect = cell.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();
    let top = cellRect.top;
    const left = cellRect.left + (cellRect.width * 0.5);

    const overlayHeight = 350;
    if (top + window.scrollY + overlayHeight > window.innerHeight) {
      top = window.innerHeight - overlayHeight;
    }

    setTradeSetupId(tradeId);
    setExitOverlayPosition({ top: top, left })
  }


  const confirmAdd = (trade: IAutoTrade) => {
    setActiveTrade(trade);
    setShowAddModal(true);
  }

  const confirmClose = (trade: IAutoTrade) => {
    setActiveTrade(trade);
    setShowCloseModal(true);
  }

  const onClickTradeGroup = (groupId: number) => {
    if (expandedTradeGroups.includes(groupId)) {
      setExpandedTradeGroups(expandedTradeGroups.filter(v => v !== groupId));
    } else {
      setExpandedTradeGroups([...expandedTradeGroups, groupId]);
    }
  }

  const renderQuantityDetail = (quantity: IQuantity) => {

    return (
      <div className="d-flex flex-column gap-1 text-nowrap">
        {quantity.total}
        <div>
          {`A:${quantity.active} C:${quantity.closed} O:${quantity.other}`}
        </div>

      </div>
    );
  }

  const renderGroup = (group: IGroupedTrade) => {
    return (
      <tr className="trade-group" key={`row-${group.setupId}`}>
        <td colSpan={3} className="bg-lighter-charcoal">
          <div className="d-flex flex-nowrap gap-1 align-items-center fw-bold">
            <div onClick={(e) => onClickTradeGroup(group.setupId)}>{expandedTradeGroups.includes(group.setupId) ? <CaretDownFill /> : <CaretRightFill />}</div>
            {TradeModel.getTradeSetupFullTitle(group.setupName, group.signalAlertId)}
            <Link state={{
              title: group.setupName,
              trades: group.trades,
              stockSymbolId: group.stockSymbolId,
              tradeSetupId: group.setupId,
              isConsensusChart: false,
              } as IConsensusState} to={'/admin/autotrade/consensus-orders-grouped'}>
              <GraphUpArrow style={{ color: 'blue' }} />
            </Link>            
            <div ref={el => tradeSetupCellRef.current[group.setupId] = el} onMouseEnter={(e) => { onMouseOverTradeCell(group.setupId); e.stopPropagation() }}><Grid3x2 style={{ color: 'blue' }} /></div>
            <div role="button" onClick={() => tradesApi.toggleTradeSetupFilter(group.setupId)}>{tradesApi.filters.setup.value ? <FunnelFill color='gray' /> : <Funnel color='blue' />}</div>
            <div><LinkAdminTradeSetup tradeSetupId={group.setupId} /></div>
          </div>
        </td>
        <td className="text-end">
          {renderQuantityDetail(TradeModel.getTradesTotalQuantity(group.trades))}
        </td>
        <td className="text-end">{group.alertScore}</td>
        <td className="text-end">
          {formatUSCurrency(TradeModel.getOpenPriceWeightedAverage(group.trades))}
        </td>
        <td className="text-end">
          {formatUSCurrency(TradeModel.getClosePriceWeightedAverage(group.trades))}
        </td>
        <td className="text-end">
          {colorize(formatUSCurrency(TradeModel.getTradesProfitLossIbkr(group.trades).realized.profitLoss))}
        </td>
        <td colSpan={4}></td>
        <td className="text-end">{colorize(formatUSCurrency(TradeModel.getUrealizedProfitLossOptionTrades(group.trades, tradesApi.livePrices)))}</td>
        <td colSpan={5} className="text-nowrap"><SignalPerformance adminSignalAlerts={adminSignalAlerts} signalId={group.signalAlertId} simple={true} /> </td>
      </tr>
    );
  }

  const renderTradeDetail = (trades: IAutoTrade[]) => {
    return (
      <>
        {trades.map((trade, index) => <>
          <tr key={`trade-${trade.id}`} data-setup-id={trade.setupId}>
            <td>
              <div className="d-flex gap-1 align-items-center" role="button" onClick={() => { setActiveRowTradeId(trade.id === activeRowTradeId ? undefined : trade.id) }}>
                {trade.id === activeRowTradeId ? <CaretDownFill /> : <CaretRightFill />}
                {trade.id}
              </div>
            </td>
            <td className="text-nowrap">
              <div className="d-flex flex-row flex-nowrap gap-1">
                {getSecurityNameFilter(trade, index)}
              </div>
            </td>
            <td>{AutoTradePositionTypeEnumHelper.names.get(trade.positionTypeId)}</td>
            <td className="text-end">{trade.openQuantity}</td>
            <td></td>
            <td className="text-end">{formatUSCurrency(trade.ibkr.openPriceAvg)}</td>
            <td className="text-end">{formatUSCurrency(trade.ibkr.closePriceAvg)}</td>
            <td className="text-end">{colorize(formatUSCurrency(AutoTradeHelper.calcRealizedProfitLossTradeIbkr(trade)))}</td>
            <td><NewYorkDate dateInMs={trade.openAt * 1000} /></td>
            <td>
              <div className="d-flex gap-2">
                {trade.ibkr.openOrders.length > 0 && trade.ibkr.openOrders[0].optionName && <button className="btn btn-primary text-13" onClick={() => confirmAdd(trade)}><PlusCircle title="Add Trade" /></button>}
                {trade.statusId === AutoTradeStatusEnum.ACTIVE &&
                  <button className="btn text-13 bg-red text-white" onClick={() => confirmClose(trade)}><XCircle title="Close Trade" /></button>
                }
              </div>
            </td>
            {trade.statusId === AutoTradeStatusEnum.ACTIVE ?
              <>{tradesApi.renderLivePrices(trade)}</>
              :
              <>
                {showRefreshTradeIds.includes(trade.id) ?
                  <TradeLivePrice trade={trade} />
                  :
                  <td colSpan={3}>
                    {trade.ibkr.openOrders.length > 0 &&
                      <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-primary" onClick={() => setShowRefreshTradeIds([
                          ...showRefreshTradeIds,
                          trade.id
                        ])}><ArrowClockwise /></button>
                      </div>
                    }
                  </td>
                }
              </>
            }
            {/* {tradesApi.renderLivePrices(trade)} */}
            <td>{AutoTradeManagementTypeEnumHelper.names.get(trade.managementTypeId)}</td>
            <td>{trade.closeAt ? <NewYorkDate dateInMs={trade.closeAt * 1000} /> : ''}</td>
            <td>
              <div className="d-flex gap-1">
                {AutoTradeStatusReasonEnumHelper.names.get(trade.statusReasonId)}
                <div role="button" onClick={() => tradesApi.filters.statusReason.value ? tradesApi.filters.statusReason.set(undefined) : tradesApi.filters.statusReason.set(trade.statusReasonId)}>{tradesApi.filters.statusReason.value ? <FunnelFill color='gray' /> : <Funnel color='blue' />}</div>
              </div>
            </td>
            <td>{TriggerExitTypeEnumHelper.names.get(trade.closeTriggerTypeId)}</td>
            <td>{AutoTradeStatusEnumHelper.names.get(trade.statusId)}</td>
          </tr>
          {(trade.ibkr.openOrders.length > 0 || trade.ibkr.closeOrders.length > 0) && trade.id === activeRowTradeId && <tr>
            <td colSpan={21}>
              <div className="d-flex flex-row gap-2">
                {trade.ibkr.openOrders.length > 0 &&
                  <div className="d-flex flex-column border p-3">
                    <div className="fw-bold">Open Orders</div>
                    <IbkrOrder orders={trade.ibkr.openOrders} />
                  </div>
                }
                {trade.ibkr.closeOrders.length > 0 &&
                  <div className="d-flex flex-column border p-3">
                    <div className="fw-bold">Close Orders</div>
                    <IbkrOrder orders={trade.ibkr.closeOrders} />
                  </div>
                }
              </div>
            </td>
          </tr>
          }
        </>

        )}
      </>
    )
  }

  const collapseAll = () => {
    setExpandedTradeGroups([]);
  }

  const expandAll = () => {
    setExpandedTradeGroups(groupedTrade.map(gt => gt.setupId));
  }

  return (
    <>
      {activeTrade && <ManualTradeAddModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
        title='Add Trade'
        onConfirm={addTrade}
        trade={activeTrade}
        isSubmitting={isAdding}
      />}

      {activeTrade && <ManualTradeCloseModal
        show={showCloseModal}
        handleClose={() => setShowCloseModal(false)}
        title='Close Trade'
        onConfirm={closeTrade}
        trade={activeTrade}
        isSubmitting={isDeleting}
      />}

      <div>
        <div className="d-flex gap-3 justify-content-start align-items-center">
          <div className="page-title my-3">Trades By Setup</div>
          <IbkrAccountStatus />
        </div>

        <tradesApi.filterComponent />
        <div className="fixed-table-head admin-trades-list">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th colSpan={2}>
                  <div className="d-flex gap-2">
                    <button type="button" className="btn btn-primary" onClick={expandAll}><ArrowsExpand title="Expand All" /></button>
                    <button type="button" className="btn btn-primary" onClick={collapseAll}><ArrowsCollapse title="Collapse All" /></button>
                    <div>
                      Trade Setup Name / <br />
                      ID | Security Name
                    </div>
                  </div>
                </th>
                <th>Position Type</th>
                <th className="text-end">Trade Setup Quantity</th>
                <th className="text-end">Alert Score</th>
                <th className="text-end">IBKR Open Price</th>
                <th className="text-end">IBKR Close Price</th>
                <th className="text-end">Profit/Loss</th>
                <th>Opened On</th>
                <th>Trade</th>
                <th className="text-end">Bid Price</th>
                <th className="text-end">Ask Price</th>
                <th className="text-end">Last Price/<br />Unrealized P/L</th>
                <th>Exit Mode</th>
                <th>Close On</th>
                <th>Trade Reason</th>
                <th>Exit Trigger</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                groupedTrade.map((group, index) =>
                  <>
                    {renderGroup(group)}
                    {expandedTradeGroups.includes(group.setupId) && renderTradeDetail(group.trades)}
                    <tr><td colSpan={18}></td></tr>
                  </>
                )
              }
            </tbody>
          </table>
        </div>

        <div ref={exitTradeOverlayRef} onMouseLeave={(e) => {
          setTradeSetupId(undefined);
          e.stopPropagation();
        }} style={{ position: "absolute", top: exitOverlayPosition.top, left: exitOverlayPosition.left }}>
          {tradeSetupId && <ExitOverlay tradeSetupId={tradeSetupId} onEscape={() => setTradeSetupId(undefined)} />}
        </div>

      </div>
    </>
  )

}
